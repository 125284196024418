// -----------------------------
// モーダル一式
// -----------------------------

function mdlSetTarget(content, key, target) {
  // 次に表示させるモーダルを設定
  let targetElem = "." + content + "[" + key + "='" + target + "']";
  return targetElem;
}
function mdlSetHeight(target) {
  // モーダルコンテンツの高さを設定
  let mdlTargetHeight =
    document.querySelector(target).firstElementChild.offsetHeight;
  let windowHeight = document.documentElement.clientHeight;
  let windowWidth = document.body.clientWidth;
  if (mdlTargetHeight > windowHeight) {
    document.querySelector(target).style.height = "90%";
  } else {
    document.querySelector(target).style.height =
      mdlTargetHeight + windowWidth * 0.06 + "px";
  }
}

function mdlinit(elm) {
  const d = document;
  const mdlTrigger = ".mdl"; //トリガーclass
  const mdlTarget = "data-href"; //モーダルを開くターゲット
  const mdlGroup = "data-mdlgroup"; //グルーピング用のdata属性
  const mdlShowClass = "modal-show"; //表示用class
  const mdlActiveClass = "modal-active"; //bodyに設置するclass
  const mdlContent = "modal-content"; //モーダルのコンテンツclass
  const mdlBg = "modal-bg"; //背景class
  const mdlClose = "modal-close"; //閉じるボタンclass
  const mdlNextArrow = "modal-arrow-next"; //グルーピング次への矢印class
  const mdlPrevArrow = "modal-arrow-prev"; //グルーピング前への矢印class
  // トリガーにグループ用のdata属性がセットされていたらコンテンツにも同dataをセットする
  d.querySelectorAll(mdlTrigger).forEach(function (e) {
    const checkGroup = e.getAttribute(mdlGroup);
    if (checkGroup !== undefined && checkGroup !== null) {
      const targetHref = e.getAttribute(mdlTarget);
      d.querySelector(targetHref).setAttribute(mdlGroup, checkGroup);
    }
  });
  // モーダルを立ち上げる要素を取得
  const targetHref = elm.getAttribute(mdlTarget);
  // モーダル表示用class
  d.querySelector(targetHref).classList.add(mdlShowClass);
  // モーダルコンテンツの高さとウィンドウの高さを取得して比較
  mdlSetHeight(targetHref);
  // bodyに背景と閉じるボタンを追加する＆スクロール禁止用のclassを追加
  const bg = d.createElement("div");
  const closeBtn = d.createElement("div");
  bg.classList.add(mdlBg, "mdl-close-trigger");
  closeBtn.classList.add(mdlClose, "mdl-close-trigger");
  d.body.appendChild(bg);
  d.body.appendChild(closeBtn);
  d.body.classList.add(mdlActiveClass);
  // グルーピング用のdata属性が設定されていたら左右の矢印を出す
  let checkGroup = elm.getAttribute(mdlGroup);
  if (checkGroup !== undefined && checkGroup !== null) {
    const prevBtn = d.createElement("button");
    const nextBtn = d.createElement("button");
    prevBtn.classList.add(mdlPrevArrow, "mdl-arrow-trigger");
    nextBtn.classList.add(mdlNextArrow, "mdl-arrow-trigger");
    d.body.appendChild(prevBtn);
    d.body.appendChild(nextBtn);
    const arrowTrigger = d.querySelectorAll(".mdl-arrow-trigger");
    arrowTrigger.forEach(function (e) {
      e.addEventListener("click", function (arw) {
        // グループ化されたモーダルの左右矢印をクリックした時
        // 現在表示中のモーダルのグループを確認
        const current = d.getElementsByClassName(mdlShowClass);
        const currentGroup = current[0].getAttribute(mdlGroup);
        // ナンバリング用のdata属性を作る
        let numKey = "data-" + currentGroup + "-num";
        let count = 0;
        const modalContents = d.getElementsByClassName(mdlContent);
        [...modalContents].forEach((e) => {
          // 各モーダルコンテンツから同一グループのものを探し出してナンバリング
          if (e.getAttribute(mdlGroup) === currentGroup) {
            count++;
            e.setAttribute(numKey, count);
          }
        });
        // 現在のdataナンバーを取得
        let currentNum = Number(current[0].getAttribute(numKey));
        let targetNum;
        if (arw.target.classList.contains(mdlNextArrow)) {
          // 次への矢印を押した時
          targetNum = currentNum + 1;
        } else {
          // 前への矢印を押した時
          targetNum = currentNum - 1;
        }
        // 次に表示させるモーダルを定義
        let mdlNextTarget = mdlSetTarget(mdlContent, numKey, targetNum);
        if (d.querySelector(mdlNextTarget) === null && targetNum == 0) {
          // これ以上前へ戻れない時は最後のモーダルへ(ループ)
          mdlNextTarget = mdlSetTarget(mdlContent, numKey, count);
        } else if (d.querySelector(mdlNextTarget) === null) {
          // これ以上次へ進めない時は最初のモーダルへ(ループ)
          mdlNextTarget = mdlSetTarget(mdlContent, numKey, 1);
        }
        // 現在のモーダルを非表示
        current[0].classList.remove(mdlShowClass);
        // 次に表示させるモーダルを表示
        d.querySelector(mdlNextTarget).classList.add(mdlShowClass);
        // 次に表示させるモーダルの高さを計算
        mdlSetHeight(mdlNextTarget);
      });
    });
  }
  const closeTrigger = d.querySelectorAll(".mdl-close-trigger");
  closeTrigger.forEach(function (e) {
    e.addEventListener("click", function () {
      // 背景もしくは閉じるボタンがクリックされたら要素を消す＆モーダル表示用classを外す
      d.querySelector("." + mdlShowClass).setAttribute("style", "");
      d.querySelector("." + mdlShowClass).classList.remove(mdlShowClass);
      d.body.classList.remove(mdlActiveClass);
      d.querySelector("." + mdlBg).remove();
      d.querySelector("." + mdlClose).remove();
      if (d.querySelector(".mdl-arrow-trigger")) {
        d.querySelector("." + mdlNextArrow).remove();
        d.querySelector("." + mdlPrevArrow).remove();
      }
    });
  });
}

(function () {
  // トリガーがあればイベント登録
  const modals = document.querySelectorAll(".mdl");
  modals.forEach((elm) => {
    elm.addEventListener("click", function () {
      mdlinit(elm);
    });
  });
})();

// -----------------------------
// グローバル変数はここで定義しておく
// -----------------------------
let scrollAmount = 0;
let indexCheck = false;
let kvHeight = "";

// -----------------------------
// 関数群
// -----------------------------

//js処理用メディアクエリ
function media() {
  let mediaFlg;
  const width = window.innerWidth;
  if (width > 1024) {
    mediaFlg = "pc";
  } else if (width >= 600) {
    mediaFlg = "tablet";
  } else if (width <= 599) {
    mediaFlg = "sp";
  }
  return mediaFlg;
}

/* 要素を取得する */
function getElements(elem) {
  let targetElement = document.querySelectorAll(elem);
  targetElement = [...targetElement];
  return targetElement;
}

/* 第一引数：イベントトリガー要素 第二引数：イベントタイプ 第三引数：実行したい関数名 */
function eventRunner(target, handle, callback) {
  if (document.querySelector(target) != null) {
    let eventTargetElement = getElements(target);
    eventTargetElement.forEach(function (elem, index) {
      if (handle === "none") {
        callback(elem);
      } else {
        elem.addEventListener(handle, callback);
      }
    });
  }
}

//スムーススクロール
if (document.querySelector(".scrollTrigger") != null) {
  //サイト読み込み時にスクロールターゲットの座標を設定
  window.addEventListener("load", function () {
    scrollAction();
  });
  //リサイズ時にスクロールターゲットの座標を再設定
  window.addEventListener("resize", function () {
    scrollAction();
  });
}

function scrollAction() {
  const headerHeight = document.querySelector(".header").clientHeight;
  const scrollTrigger = document.querySelectorAll(".scrollTrigger");
  scrollTrigger.forEach(function (elem, index) {
    const scrollTargetAttr = elem.getAttribute("href");
    const scrollTargetElm = document.querySelector(scrollTargetAttr);
    const rect = scrollTargetElm.getBoundingClientRect();
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollTargetTop = rect.top + scrollTop;

    elem.addEventListener("click", function (event) {
      event.preventDefault();
      window.scrollTo({
        top: scrollTargetTop - headerHeight,
        left: 0,
        behavior: "smooth",
      });
    });
  });
}

// スクロールでヘッダーとサイドバーを表示/非表示
function toggleHeaderAndSidebar(val, distance, identify = [false, 0]) {
  //console.log(identify);

  if (val < document.documentElement.scrollTop && val > distance) {
    document.querySelector(".header").classList.add("is-hide");
  } else {
    document.querySelector(".header").classList.remove("is-hide");
  }
  if (
    identify[0] === true &&
    identify[1] > document.documentElement.scrollTop
  ) {
    document.querySelector(".header").classList.add("hide-first");
    document.querySelector(".float-bnr").classList.add("hide-first");
  } else {
    document.querySelector(".header").classList.remove("hide-first");
    document.querySelector(".float-bnr").classList.remove("hide-first");
  }
  val = document.documentElement.scrollTop;
  return val;
}

function quantityBtn(event) {
  const current = event.target.parentNode.querySelector(".current-quantity");
  let currentNum = Number(current.value);
  if (event.target.classList.contains("btn-quantity-minus") && currentNum > 1) {
    currentNum -= 1;
  } else if (event.target.classList.contains("btn-quantity-plus")) {
    currentNum += 1;
  }
  current.value = currentNum;
}

function cartinBtn(btn) {
  const wrapElm = btn.target.closest(".product-info");
  const qty = wrapElm.querySelector(".current-quantity").value;
  const productType =
    document.forms["purchase-form"].elements["cart_item_type"].value;
  const pid = document.forms["purchase-form"].elements["id"].value;
  let vid;
  if (productType === "regular_course") {
    vid =
      document.forms["purchase-form"].elements["products[][variant_id]"].value;
  }
  const name = wrapElm.querySelector(".product-name").textContent;
  const course = wrapElm.querySelector(".product-course").textContent;
  const url = location.href;

  let cartinList;
  cartinList = localStorage.getItem("cartinList");
  let pushFlg = true;
  if (cartinList !== null) {
    cartinList = JSON.parse(localStorage.getItem("cartinList"));
    for (let index = 0; index < cartinList.length; index++) {
      const listItem = cartinList[index];
      const pidInList = listItem["pid"];
      if (pidInList === pid) {
        let qtyInList = Number(cartinList[index]["qty"]);
        qtyInList += Number(qty);
        cartinList[index]["qty"] = qtyInList;
        pushFlg = false;
        break;
      }
    }
    pushData(cartinList, pushFlg);
  } else {
    cartinList = [];
    pushData(cartinList, pushFlg);
  }

  function pushData(list, flg) {
    if (flg === true) {
      list.push({
        name: name,
        course: course,
        productType: productType,
        pid: pid,
        vid: vid,
        qty: qty,
        url: url,
      });
    }
    localStorage.setItem("cartinList", JSON.stringify(list));
  }

  //console.log(cartinList);
  location.reload();
}

function headerCart(cartElm) {
  let cartinList = localStorage.getItem("cartinList");
  if (cartinList !== null) {
    document.querySelector(".cart-with-items").classList.add("is-active");
    document.querySelector(".cart-no-items").classList.remove("is-active");
    cartinList = JSON.parse(localStorage.getItem("cartinList"));

    document
      .querySelector(".js-toggle-cvlist")
      .setAttribute("data-cartnums", cartinList.length);

    cartinList.forEach((e, i) => {
      let li = document.createElement("li");
      const input1 =
        '<input type="hidden" name="items[' + i + ']id" value="' + e.pid + '">';
      const input2 =
        '<input type="hidden" name="items[' +
        i +
        ']cart_item_type" value="' +
        e.productType +
        '">';
      let input3 = "";
      if (e.vid !== undefined) {
        input3 =
          '<input type="hidden" name="items[' +
          i +
          ']products[][variant_id]" value="' +
          e.vid +
          '">';
      }
      const input4 =
        '<input type="number" name="items[' +
        i +
        ']quantity" value="' +
        e.qty +
        '" class="qty">';
      const productData =
        '<p class="fz-s"><a href="' +
        e.url +
        '" class="product-name d-b">' +
        e.name +
        '</a><span class="product-course d-ib bg-grey fz-xs px-xs">' +
        e.course +
        "</span></p>";
      li.insertAdjacentHTML("beforeend", input1);
      li.insertAdjacentHTML("beforeend", input2);
      li.insertAdjacentHTML("beforeend", input3);
      li.insertAdjacentHTML("beforeend", productData);
      li.insertAdjacentHTML("beforeend", input4);
      document.querySelector(".cartin-items").appendChild(li);
    });
  }

  // console.log(cartinList);
}

// -----------------------------
// イベント系
// -----------------------------

// DOM読み込まれたら発火
window.addEventListener("DOMContentLoaded", function () {
  // 商品詳細数量ボタン
  // eventRunner(".js-btn-quantity", "click", quantityBtn);

  indexCheck = document
    .querySelector(".header")
    .classList.contains("hide-first");
  if (indexCheck) {
    kvHeight = document.querySelector(".index-kv").clientHeight;
  }
});

// リサイズ時に発火
window.addEventListener("resize", function () {});

// スクロール時に発火
window.addEventListener("scroll", function () {
  // スクロールでヘッダーとサイドバーを表示/非表示
  scrollAmount = toggleHeaderAndSidebar(scrollAmount, 200, [
    indexCheck,
    kvHeight,
  ]);
});

// -----------------------------
// その他
// -----------------------------

//スクロールでアニメーションクラスを追加
if (document.querySelector(".anm") != null) {
  const anm = document.querySelectorAll(".anm");
  const options = {
    root: null,
    rootMargin: "-8%",
    threshold: [0],
  };
  const observe = new IntersectionObserver(addAnimation, options);
  anm.forEach(function (elem, index) {
    observe.observe(elem);
  });
  function addAnimation(entries) {
    entries.forEach(function (entry) {
      if (entry.isIntersecting) {
        entry.target.classList.add("is-show");
      } else {
        // entry.target.classList.remove("is-show");
      }
    });
  }
}

// アコーディオンパネル
document.addEventListener("DOMContentLoaded", () => {
  const title = document.querySelectorAll(".js-accordion-trigger");

  for (let i = 0; i < title.length; i++) {
    let titleEach = title[i];
    let content = titleEach.nextElementSibling;
    let contentHeight = content.clientHeight;
    content.style.height = 0 + "px";
    titleEach.addEventListener("click", () => {
      titleEach.classList.toggle("is-active");
      content.classList.toggle("is-open");
      if (content.classList.contains("is-open")) {
        content.style.height = contentHeight + "px";
      } else {
        content.style.height = 0 + "px";
      }
    });
  }
});

// アコーディオンパネル
document.addEventListener("DOMContentLoaded", () => {
  setUpAccordion();
});

const setUpAccordion = () => {
  const details = document.querySelectorAll(".js-details");
  const isRunning = "running"; // アニメーション実行中のときに付与する予定のカスタムデータ属性の値
  const isOpend = "is-opened"; // アイコン操作用のクラス名

  details.forEach((element) => {
    const summary = element.querySelector(".js-summary");
    const content = element.querySelector(".js-content");

    summary.addEventListener("click", (event) => {
      event.preventDefault();
      if (element.dataset.animStatus === isRunning) {
        return;
      }
      if (element.open) {
        element.classList.toggle(isOpend);
        const closingAnim = content.animate(
          closingAnimKeyframes(content),
          animTiming
        );

        element.dataset.animStatus = isRunning;
        closingAnim.onfinish = () => {
          element.removeAttribute("open");
          element.dataset.animStatus = "";
        };
      } else {
        element.setAttribute("open", "true");
        element.classList.toggle(isOpend);

        const openingAnim = content.animate(
          openingAnimKeyframes(content),
          animTiming
        );
        element.dataset.animStatus = isRunning;
        openingAnim.onfinish = () => {
          element.dataset.animStatus = "";
        };
      }
    });
  });
};

/* アニメーションの時間とイージング */
const animTiming = {
  duration: 400,
  easing: "ease-out",
};

/*　アコーディオンを閉じるときのキーフレーム　*/
const closingAnimKeyframes = (content) => [
  {
    height: content.offsetHeight + "px",
    opacity: 1,
  },
  {
    height: 0,
    opacity: 0,
  },
];

/* アコーディオンを開くときのキーフレーム */
const openingAnimKeyframes = (content) => [
  {
    height: 0,
    opacity: 0,
  },
  {
    height: content.offsetHeight + "px",
    opacity: 1,
  },
];

// -----------------------------
// swiper系
// -----------------------------

(function () {
  //swiper
  let kvSlide = new Swiper(".js-kv-slide", {
    loop: true, // ループ有効
    slidesPerView: 5, // 一度に表示する枚数
    speed: 6000, // ループの時間
    allowTouchMove: false, // スワイプ無効
    autoplay: {
      delay: 0, // 途切れなくループ
    },
    breakpoints: {
      769: {
        slidesPerView: 8,
      },
    },
  });

  //商品詳細サムネイル
  const sliderThumbnail = new Swiper(".js-pd-thumb-slide", {
    slidesPerView: 5,
    // freeMode: true,
    // watchSlidesVisibility: true,
    // watchSlidesProgress: true,
    spaceBetween: 10,
    navigation: {
      nextEl: ".swiper-btn-next",
      prevEl: ".swiper-btn-prev",
    },
  });

  //商品詳細スライダー
  const slider = new Swiper(".js-pd-slide", {
    thumbs: {
      swiper: sliderThumbnail[0],
    },
  });
  console.log(sliderThumbnail.length);
  console.log(slider);
})();
